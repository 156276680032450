import React from 'react'

const GlyphFolder = props => (
  <svg viewBox='0 0 18.42 21' {...props}>
    <path
      d='M16.85 8v-.6a1.34 1.34 0 0 0-1.3-1.4h-5.2L8.4 4.66H3.85A1.33 1.33 0 0 0 2.55 6v2z'
      opacity={0.45}
    />
    <path
      d='M2.55 8.32V15a1.31 1.31 0 0 0 1.3 1.32h11.7a1.31 1.31 0 0 0 1.3-1.32V8.32z'
      opacity={0.1}
    />
    <path d='M15.55 16.59H3.85A1.55 1.55 0 0 1 2.3 15V6a1.55 1.55 0 0 1 1.55-1.59H8.4a.23.23 0 0 1 .14 0l1.88 1.29h5.13a1.56 1.56 0 0 1 1.55 1.55V15a1.55 1.55 0 0 1-1.55 1.59zM3.85 4.91A1 1 0 0 0 2.8 6v9a1 1 0 0 0 1.05 1.05h11.7A1 1 0 0 0 16.6 15V7.25a1.06 1.06 0 0 0-1.05-1h-5.2a.23.23 0 0 1-.14 0L8.32 4.91z' />
    <path d='M2.62 7.9h14.3v.5H2.62z' />
  </svg>
)

export default GlyphFolder
