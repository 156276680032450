import React from 'react'

const GlyphDocGeneric = props => (
  <svg viewBox='0 0 18.42 21' {...props}>
    <path
      d='M10.22 2.37H4.64a1.11 1.11 0 0 0-1.11 1.11v14a1.11 1.11 0 0 0 1.11 1.11h9.14a1.11 1.11 0 0 0 1.1-1.11V7z'
      opacity={0.1}
    />
    <path d='M14.88 7.49h-2.66A2.25 2.25 0 0 1 10 5.24V2.58a.25.25 0 0 1 .5 0v2.66A1.76 1.76 0 0 0 12.22 7h2.66a.25.25 0 0 1 0 .5z' />
    <path d='M13.78 18.88H4.64a1.36 1.36 0 0 1-1.36-1.36v-14a1.36 1.36 0 0 1 1.36-1.4h5.58a.25.25 0 0 1 .18.08l4.66 4.66a.22.22 0 0 1 .07.17v10.49a1.35 1.35 0 0 1-1.35 1.36zM4.64 2.62a.87.87 0 0 0-.86.86v14a.87.87 0 0 0 .86.86h9.14a.86.86 0 0 0 .85-.86V7.14l-4.51-4.52z' />
    <path d='M10.22 2.58v2.66a2 2 0 0 0 2 2h2.66' opacity={0.45} />
  </svg>
)

export default GlyphDocGeneric
